.hide-on-mobile {
	display: block !important;
}

.hide-on-desktop {
	display: none !important;
}

.hide-on-mobile-flex {
	display: flex !important;
}

.hide-on-desktop-flex {
	display: none !important;
}

@media (min-width: 320px) and (max-width: 990px) {
	.hide-on-mobile {
		display: none !important;
	}

	.hide-on-desktop {
		display: block !important;
	}

	.hide-on-mobile-flex {
		display: none !important;
	}

	.hide-on-desktop-flex {
		display: flex !important;
	}
}
.userprofile-dd {
	display: block !important;
	height: 0 !important;
	transition: all ease-in-out 300ms !important;
	opacity: 0;
}
.userprofile-dd.open {
	height: 90px !important;
	opacity: 1;
}
.user-search-dropdown .ui-menu-item:hover div,
.select2-results__option:hover {
	background-color: var(--theme1-bg);
	color: #fff;
	padding-left: 5px;
}
.user-search-dropdown .ui-menu-item div {
	padding-left: 5px;
}
.user-search-dropdown .ui-menu-item {
	line-height: 1.5;
}
.mobile-login-icon {
	position: relative !important;
	right: 0 !important;
	top: 0 !important;
}
.react-datepicker-wrapper {
	width: 100%;
}
.custom-sidebar {
	opacity: 0;
	left: -300px !important;
	transition: all ease-in-out 300ms;
}
.custom-sidebar.active {
	opacity: 1;
	left: 0px !important;
}
.cursor-pointer {
	cursor: pointer;
}
.tick .tick-credits {
	display: none;
}
.tick {
	position: absolute !important;
	bottom: 10px;
	right: 10px;
	font-size: 3em;
	font-weight: bold;
	/* transform: scale(2); */
}
.tick-flip-panel {
	color: #eee;
	background-color: #4a274f !important;
}
.tick-flip-panel-back:after {
	background-image: none !important;
}

.hide-scrollbar-css {
	overflow: scroll;
	overflow-x: hidden;
}
.hide-scrollbar-css::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.hide-scrollbar-css::-webkit-scrollbar-thumb {
	background: #ff0000;
}
.icon-range.show {
	transition: all 100ms ease-in-out;
	overflow: hidden;
}
.min-max-info-active-2 {
	height: 48px !important;
	padding: 6px 14px !important;
}
.min-max-info-3 {
	height: 0px !important;
	padding: 0px 14px !important;
	overflow: hidden;
	transition: all 150ms ease-in-out;
}
.min-max-info-active-3 {
	height: 42px !important;
	padding: 6px 14px !important;
}

.casino-result-desc {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	padding: 6px;
	box-shadow: 0 0 4px -1px;
	margin-top: 10px;
}

.casino-result-desc-item {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin: 5px;
}

.casino-result-desc-item div:first-child {
	margin-right: 2%;
	opacity: 0.6;
}

.casino-result-desc-item div:last-child {
	word-break: break-all;
}

/* ----------------------------------------------------------- */

.casino-result-desc {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	padding: 6px;
	box-shadow: 0 0 4px -1px;
	margin-top: 10px;
}

.casino-result-desc-item {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin: 5px;
}

.casino-result-desc-item div:first-child {
	margin-right: 2%;
	opacity: 0.6;
}

.casino-result-desc-item div:last-child {
	word-break: break-all;
}
.responsive-custom-modal {
	width: 96vw;
}
.responsive-game-listing-container {
	width: 100% !important;
}
.custom-date-picker {
	display: inline-block;
	width: 100%;
	height: 38px;
	padding: 6px 30px;
	padding-left: 10px;
	font-size: 14px;
	line-height: 1.4;
	color: #555;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-datepicker-icon {
	position: absolute;
	top: 0;
	z-index: 999;
	right: 10px;
	width: 20px;
}
.left-datepicker .react-datepicker-popper {
	transform: translate(42.5px, 48px) !important;
}
.right-datepicker .react-datepicker-popper {
	transform: translate(-97.5px, 48px) !important;
}
.gap-10 {
	gap: 10px;
}
.box-1 .d-block {
	color: #000 !important;
}
